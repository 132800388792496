import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import MenuInterface from '../classes/menu-interfaces';

export function overlayPopup() {
  new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '.js-close-overlay-popup',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
    timeout: {
      delay: 300,
    },
  }).init();
}

export function overlayDropdown() {
  const overlayName = 'dropdown';

  if (!document.querySelector(`[data-overlay="${overlayName}"]`)) {
    return;
  }

  const overlay = new Overlay({
    name: overlayName,
    click: {
      buttons: {
        trigger: `[data-overlay-trigger="${overlayName}"]`,
        close: `[data-overlay-close="${overlayName}"]`,
        switch: '[data-overlay-toggle="search"]',
      },
    },
    animations: {
      selector: '.js-overlay-dropdown-sections',
      styles: [
        {
          property: 'height',
          value: 'dataset',
          easing: 'easeOutCubic',
        },
      ],
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    },
  });

  overlay.init();
}

export function overlayMobileMenu() {
  const overlayName = 'mobile-menu';

  if (!document.querySelector(`[data-overlay="${overlayName}"]`)) {
    return;
  }

  const overlay = new Overlay({
    name: overlayName,
    events: {
      open: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        toggle: `[data-overlay-toggle="${overlayName}"]`,
        close: `[data-overlay-close="${overlayName}"]`,
        switch: '[data-overlay-toggle="search"]',
      },
    },
    options: {
      speed: 1000,
      goToSelector: 'html, body',
      closeOnResize: false,
    },
  });

  overlay.init();

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseCompleteOverlayMobile-menu',
    function: MenuInterface.reset,
  });
  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onOpenOverlayMobile-menu',
    function: MenuInterface.addAnimation,
  });
  OBSERVER.on('overlayMenu');
}

export function overlayFastLinks() {
  const overlayName = 'fast-links';

  if (!document.querySelector(`[data-overlay="${overlayName}"]`)) {
    return;
  }

  const overlay = new Overlay({
    name: overlayName,
    click: {
      buttons: {
        toggle: `[data-overlay-toggle="${overlayName}"]`,
        close: `[data-overlay-close="${overlayName}"]`,
        switch: '[data-overlay-trigger="dropdown"], [data-overlay-toggle="mobile-menu"], [data-overlay-toggle="search"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  overlay.init();
}

export function overlaySearch() {
  const overlayName = 'search';

  if (!document.querySelector(`[data-overlay="${overlayName}"]`)) {
    return;
  }

  const overlay = new Overlay({
    name: 'search',
    events: { openComplete: true },
    click: {
      buttons: {
        toggle: `[data-overlay-toggle="${overlayName}"]`,
        close: `[data-overlay-close="${overlayName}"]`,
        switch: '[data-overlay-trigger="dropdown"], [data-overlay-toggle="mobile-menu"]',
      },
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false,
    },
  });

  overlay.init();

  const onOpenComplete = () => { document.querySelector('#search-field').focus(); };

  OBSERVER.add({ name: 'overlaySearch', events: 'onOpenCompleteOverlaySearch', function: onOpenComplete });
  OBSERVER.on('overlaySearch');

  function removeSearchText() {
    document.getElementById('search-field').value = '';
    document.querySelector('.c-search__ico-x-button').classList.remove('show');
  }

  OBSERVER.add({
    name: 'removeSearchText',
    events: 'click',
    targets: '.c-search__ico-x-button',
    function: removeSearchText,
  });
  OBSERVER.on('removeSearchText');

  function toggleRemoveBtn(e) {
    if (e.currentTarget.value === '') {
      document.querySelector('.c-search__ico-x-button').classList.remove('show');
    } else {
      document.querySelector('.c-search__ico-x-button').classList.add('show');
    }
  }

  OBSERVER.add({
    name: 'toggleRemoveBtn',
    events: 'input',
    targets: '#search-field',
    function: toggleRemoveBtn,
  });
  OBSERVER.on('toggleRemoveBtn');
}

export function overlayCalendar() {
  const ovCalendar = new Overlay({
    name: 'calendar',
    click: {
      buttons: {
        open: '[data-js="open-overlay-calendar"]',
        close: '[data-js="close-overlay-calendar"]',
      },
    },
    options: {
      speed: 100,
      closeOnResize: false,
    },
  });

  ovCalendar.init();
}

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}
