// Documentation: https://swiperjs.com/swiper-api
import Swiper from 'swiper/bundle';

export function swiperHomeBanner() {
  const contentName = 'home-banner-content';
  const imagesName = 'home-banner-images';

  if (!document.querySelector(`[data-swiper="${contentName}"]`) || !document.querySelector(`[data-swiper="${imagesName}"]`)) {
    return;
  }

  window.swiperHomeBannerImages = new Swiper(`[data-swiper="${contentName}"]`, {
    slidesPerView: 1,
    loop: true,
    speed: 800,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: `[data-swiper-dots="${contentName}"]`,
      type: 'bullets',
      clickable: true,
    },
  });

  window.swiperHomeBannerContent = new Swiper(`[data-swiper="${imagesName}"]`, {
    slidesPerView: 1,
    loop: true,
    speed: 800,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  });

  window.swiperHomeBannerContent.controller.control = window.swiperHomeBannerImages;
  window.swiperHomeBannerImages.controller.control = window.swiperHomeBannerContent;
}

export function swiperQuickLinks() {
  if (document.querySelector('[data-swiper="quickLinks"]')) {
    window.swiperQuickLinks = new Swiper('[data-swiper="quickLinks"]', {
      slidesPerView: 'auto',
      spaceBetween: 45,
      navigation: {
        nextEl: '[data-swiper-next="quickLinks"]',
        prevEl: '[data-swiper-prev="quickLinks"]',
        disabledClass: 'disabled',
      },
    });
  }
}

export function swiperHomeEvents() {
  const slides = document.querySelectorAll('[data-swiper="HomeEvents"] .swiper-slide')

  if (document.querySelector('[data-swiper="HomeEvents"]')) {
    window.swiperHomeEvents = new Swiper('[data-swiper="HomeEvents"]', {
      slidesPerView: 'auto',
      loop: (slides.length > 1),
      speed: 800,
      navigation: {
        nextEl: '[data-swiper-next="HomeEvents"]',
        prevEl: '[data-swiper-prev="HomeEvents"]',
        disabledClass: 'disabled',
      },
    });
  }
}
