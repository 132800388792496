import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import tippy from 'tippy.js';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import CharacterCount from '@blankonumerique/blanko-scripts/character-count';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  calendar,
  backendKeyBind,
  ogContent,
  genericImage,
  initDeleteTextInput,
  removeBtn,
  eventAndNewsContainerHeight,
  initSopfeuWidget,
} from './functions/functions';
import {
  swiperQuickLinks,
  swiperHomeBanner,
  swiperHomeEvents,
} from './functions/sliders';
import dynamicScrollToBlock from './functions/scrollToBlock';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import { resetDropzone, formsPackage, changeSelect } from './functions/form';
// eslint-disable-next-line import/named
import {
  overlayShare,
  overlayMobileMenu,
  overlayFastLinks,
  overlaySearch,
  overlayCalendar,
  overlayDropdown,
  overlayPopup,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formExample,
  formSuccess,
  formContact,
  formJobs, formNewsletter,
} from './functions/validation';
import masksPackage from './functions/masks';
import { removeHoverOnMobile } from './functions/helper';
import bannerAlerts from './functions/banner-alerts';
import MenuInterface from './classes/menu-interfaces';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;

const initBarba = () => {
  barba.hooks.beforeEnter(async ({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    bannerAlerts();
    overlayShare();
    overlayDropdown();
    overlayMobileMenu();
    overlayFastLinks();
    overlaySearch();
    overlayCalendar();
    overlayPopup();
    backendKeyBind();
    swiperQuickLinks();
    swiperHomeBanner();
    swiperHomeEvents();
    initSopfeuWidget();
    ShareButtons.update();
    SCROLLFIRE.init();
    tippy('[data-tippy-content]', {
      theme: 'light',
      placement: 'left-end',
      arrow: false,
    });
    removeHoverOnMobile();
    dynamicScrollToBlock();
    MenuInterface.init();
    CharacterCount.init({ className: 'u-text' });
    await Scroller.init();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          formContact();
          formJobs();
          formNewsletter();
          calendar();
          genericImage();
          initDeleteTextInput();
          removeBtn();
          changeSelect('#select-eventsList');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          dynamicPackage();
          eventAndNewsContainerHeight();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
