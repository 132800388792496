// |--------------------------------------------------------------------------
// | Banner alerts
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner la
// | bannière des alertes.
// |
// | IMPORTS _____
// | -> jQuery
// | -> Observer
// |
// | OPTIONS _____
// | container => Le bloc de la bannière d'alerte.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total'
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';
// ---------------------------------------------------------------------------

export default function bannerAlerts(options = {}) {
  // Déclaration des propriétés par défaut
  const object = {};
  object.container = options.container || '[data-banner-alerts]';
  object.slider = options.slider || '[data-banner-alerts="slider"]';
  object.slide = options.slide || '[data-banner-alerts="slide"]';
  object.navigation = options.navigation || '[data-banner-alerts="navigation"]';
  object.prev = options.prev || '[data-banner-alerts="prev"]';
  object.next = options.next || '[data-banner-alerts="next"]';
  object.close = options.close || '[data-banner-alerts="close"]';
  object.pagination = options.pagination || '[data-banner-alerts="pagination"]';
  object.showClass = options.showClass || 'displayed';
  object.show0 = options.show0 || false;

  // Déclarations des variables
  const container = document.querySelector(object.container);

  // Déclarations des fonctions
  const closeAlert = () => document.documentElement.style.setProperty('--alerts-height', '0px');

  // Ajout de l événement pour masquer la bannière d'alertes
  OBSERVER.add({
    name: 'alerts',
    events: 'click',
    function: closeAlert,
    targets: object.close,
  });
  OBSERVER.on('alerts');

  // Si la page contient le container banner-alert
  if (typeof (container) !== 'undefined' && container != null) {
    // Si il y a plus qu'une slide
    if (document.querySelectorAll(object.slide).length > 1) {
      // Création du slider
      window.bannerAlertsSwiper = new Swiper(object.slider, {
        threshold: 10,
        loop: true,
        slidesPerView: 1,
        speed: 600,
        // allowTouchMove: false,
        pagination: {
          el: object.pagination,
          type: 'fraction',
        },
        navigation: {
          prevEl: object.prev,
          nextEl: object.next,
        },
      });

      // Afficher les navigation de l'alerte
      document.querySelector(object.navigation).classList.add(object.showClass);
    }
  } else {
    document.documentElement.style.setProperty('--alerts-height', '0px');
  }
}
