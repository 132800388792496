import anime from 'animejs';
import { getElementOffset } from './helper';
import { OBSERVER } from '../plugins';

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (object = {}) => {
  const options = {};
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const wd = window.document;
  const scrollbar = wd.scrollingElement || wd.body || wd.documentElement;
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo;

  const animation = anime
    .timeline({
      targets: scrollbar,
      duration: options.duration,
      easing: options.easing,
    })
    .add({ scrollTop: getElementOffset({ element }).top + options.offset });

  return animation.finished;
};

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (object) => {
  const options = {};
  options.selector = object.selector;
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  });

  OBSERVER.add({
    name: 'scrollToBlock',
    events: 'click',
    targets: options.selector,
    function: onClick,
  });

  OBSERVER.on('scrollToBlock');
};

// Fonction permettant d'aller chercher dynamiquement tous les scrollto
export default function dynamicScrollToBlock() {
  const scrollButtonsSelector = 'data-scroll-button';
  const scrollButtons = document.querySelectorAll(`[${scrollButtonsSelector}]`);
  const scrollButtonsLength = scrollButtons.length;
  const scrollDestinationsSelector = 'data-scroll-destination';
  const scrollDestinations = document.querySelectorAll(`[${scrollDestinationsSelector}]`);
  const scrollDestinationsLength = scrollDestinations.length;

  let i;
  let j;

  for (i = 0; i < scrollButtonsLength; i += 1) {
    const scrollFromDatasetValue = scrollButtons[i].dataset.scrollButton;

    for (j = 0; j < scrollDestinationsLength; j += 1) {
      const scrollToDatasetValue = scrollDestinations[j].dataset.scrollDestination;
      const scrollOffsetDatasetValue = parseInt(scrollDestinations[j].dataset.scrollOffset, 10) || 0;

      if (scrollFromDatasetValue === scrollToDatasetValue) {
        clickToScrollToBlock({
          selector: `[${scrollButtonsSelector}="${scrollToDatasetValue}"]`,
          scrollTo: `[${scrollDestinationsSelector}="${scrollFromDatasetValue}"]`,
          offset: scrollOffsetDatasetValue,
        });
      }
    }
  }
}
